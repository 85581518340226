import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAIueaj3n7fzKUgLkpgjW5apQqrnkin1QY",
    authDomain: "cellix-mis.firebaseapp.com",
    projectId: "cellix-mis",
    storageBucket: "cellix-mis.appspot.com",
    messagingSenderId: "812128860598",
    appId: "1:812128860598:web:1bd5a6dfd1053f670d6917",
    measurementId: "G-PBJ3V0G6FC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export { app, auth };