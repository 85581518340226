import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import GoToTop from "./GoToTop";
import { auth } from "../../config/firebase";
import { useEffect, useState } from "react";

const Footer = () => {
    const [user, setUser] = useState(null);
    
    useEffect(() => {
        const footerLogin = auth.onAuthStateChanged((user) => {
            setUser(user);
        });
        return footerLogin;
    }, []);

    return(
        <div className="footer">
            <div className="footer-content">
                <a href="https://www.cellixbio.com/" target="_blank" rel="noreferrer">
                    <img src="https://cellixbio-assets.s3.ap-south-1.amazonaws.com/Web+Images/CellixBio.Icon.png" alt="footer-logo" style={{marginBottom: '10%'}} />
                </a>

                <a href="https://www.avacapharma.com/" target="_blank" rel="noreferrer">
                    <img src="https://avaca-pharma-assets.s3.ap-south-1.amazonaws.com/Avaca+Pharma+Main+Logo.png" alt="footer-logo" style={{marginBottom: '10%', marginLeft: '7%'}} />
                </a>

                {
                    user && user ?
                    <ul className="footer-links">
                        <li><Nav.Link className='footer_link' as={Link} to="/home" eventKey="0">Home</Nav.Link></li>
                        <li><Nav.Link className='footer_link' as={Link} to="/patents" eventKey="1">Applications</Nav.Link></li>
                        <li><Nav.Link className='footer_link' as={Link} to="/firms" eventKey="2">Firms</Nav.Link></li>
                        <li><Nav.Link className='footer_link' as={Link} to="/newpatent" eventKey="3">New Entry</Nav.Link></li>
                        <li><Nav.Link className='footer_link' as={Link} to="/notifications" eventKey="4">Notifications</Nav.Link></li>
                    </ul> : ""
                }
            </div>
            <div className="footer-bottom">
                <p>copyright &copy; 2014 - 2023 Cellix Bio</p>
            </div>
            <GoToTop></GoToTop>
        </div>
    );
}

export default Footer;